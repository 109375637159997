// authService.js
import { createApi } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from './customBaseQuery' // Import your custom base query

const settingsApi = createApi({
  reducerPath: 'settingsApi',
  baseQuery: customBaseQuery, // Use customBaseQuery
  endpoints: (builder) => ({
    updateIpAddresses: builder.mutation({
      query: ({ ipAddresses }) => ({
        url: `/settings/update-ip-addresses`,
        method: 'PUT',
        data: { ipAddresses },
      }),
    }),
    getIpAddresses: builder.query({
      query: (id) => ({
        url: `/settings/ip-addresses`,
        method: 'GET',
      }),
    }),
    toggleIpCheck: builder.mutation({
      query: (password) => ({
        url: `/toggle-ip-restriction`,
        method: 'PUT',
        data: { password },
      }),
    }),
  }),
})

export const { useUpdateIpAddressesMutation, useToggleIpCheckMutation, useLazyGetIpAddressesQuery } = settingsApi

export default settingsApi
