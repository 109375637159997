import appReducer from './appReducer'
import authReducer from './authReducer'
import userReducer from './userReducer'
import tableReducer from './tableReducer'
import pageReducer from './pageReducer'
import gameReducer from './gameReducer'
import clientReducer from './clientReducer'
import cashTagsApi from '../../services/toolkit/cashTagService'
import userApi from '../../services/toolkit/userService'
import notificationApi from '../../services/toolkit/notificationService'
import settingsApi from '../../services/toolkit/settingsService'

const rootReducer = {
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  table: tableReducer,
  page: pageReducer,
  game: gameReducer,
  client: clientReducer,
  [cashTagsApi.reducerPath]: cashTagsApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
}

export default rootReducer
