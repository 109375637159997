import React, { lazy } from 'react'
import { Route, Navigate, createBrowserRouter, createRoutesFromElements, defer, RouterProvider } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ProtectedRoute } from './routes/ProtectedRoute'
import { getUserProfile } from './store/actions/authActions'
import { AuthLayout } from './layouts/AuthLayout'
import { HomeLayout } from './layouts/HomeLayout'

const LoginPage = lazy(() => import('./pages/Login'))
const ToggleIps = lazy(() => import('./pages/ToggleIps'))
const DashboardLayout = lazy(() => import('./layouts/DashboardLayout.js'))
const NotFoundPage = lazy(() => import('./pages/NotFound'))

const getUserData = (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      if (localStorage.access_token || localStorage.getItem('access_token')) {
        const response = await dispatch(getUserProfile())
        resolve(response)
      } else {
        resolve({})
      }
    } catch (e) {
      resolve({})
    }
  })

const App = () => {
  const dispatch = useDispatch()

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<AuthLayout />} loader={() => defer({ userPromise: getUserData(dispatch) })}>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<Navigate to="/login" />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="toggle-ips-check" element={<ToggleIps />} />
        </Route>
        <Route
          path="dashboard/*"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          {/* <Route index element={<>Dashboard</>} />
          <Route path="users" element={<>Users</>} /> */}
        </Route>

        <Route path="*" element={<Navigate to="/404" />} />

        <Route path="404" element={<NotFoundPage />} />
      </Route>,
    ),
  )
  return <RouterProvider router={router} />
}
export default App
